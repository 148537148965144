<template>
  <v-container id="AadRedirectView" fill-height fluid>
    <v-row justify="center">
      <h1 class="primary--text pb-4">Redirecting...</h1>
      <v-progress-linear
        color="primary"
        indeterminate
        height="6"
      ></v-progress-linear>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AadRedirectView",
  methods: {
    ...mapActions({
      requestCreateAccessToken: "requestCreateAccessToken",
      requestVerifyAccessToken: "requestVerifyAccessToken",
    }),
  },
  async mounted() {
    // Get code from query string: path /auth/redirect?code=...
    const aad_code = this.$route.query.code;
    if (aad_code) {
      await this.requestCreateAccessToken(aad_code);
      await this.requestVerifyAccessToken();
      window.location.replace("/");
    }
  },
};
</script>

<style scoped>
#AadRedirect {
  height: 100vh;
  color: white;
}
</style>
